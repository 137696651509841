import { cloneElement, CSSProperties } from "react";

import { ReactComponent as PlusSvg } from "assets/icons/plus.svg";
import { ReactComponent as LockSvg } from "assets/icons/lock.svg";
import { ReactComponent as SwapSvg } from "assets/icons/swap.svg";
import { ReactComponent as BellSvg } from "assets/icons/bell.svg";
import { ReactComponent as MenuSvg } from "assets/icons/menu.svg";
import { ReactComponent as HomeSvg } from "assets/icons/home.svg";
import { ReactComponent as BookSvg } from "assets/icons/book.svg";
import { ReactComponent as UserSvg } from "assets/icons/user.svg";
import { ReactComponent as MinusSvg } from "assets/icons/minus.svg";
import { ReactComponent as HeartSvg } from "assets/icons/heart.svg";
import { ReactComponent as SearchSvg } from "assets/icons/search.svg";
import { ReactComponent as CloseAltSvg } from "assets/icons/close-alt.svg";
import { ReactComponent as CloseSvg } from "assets/icons/close-x.svg";
import { ReactComponent as FilterSvg } from "assets/icons/filter.svg";
import { ReactComponent as LogoutSvg } from "assets/icons/logout.svg";
import { ReactComponent as LoaderSvg } from "assets/icons/loader.svg";
import { ReactComponent as MapPinSvg } from "assets/icons/map-pin.svg";
import { ReactComponent as MessageSvg } from "assets/icons/message.svg";
import { ReactComponent as LocationSvg } from "assets/icons/map-pin.svg";
import { ReactComponent as SmallLogo } from "assets/icons/logo-small.svg";
import { ReactComponent as BriefcaseSvg } from "assets/icons/briefcase.svg";
import { ReactComponent as ArrowLeftSvg } from "assets/icons/arrow-left.svg";
import { ReactComponent as NavigationSvg } from "assets/icons/navigation.svg";
import { ReactComponent as ChevronDown } from "assets/icons/chevron-down.svg";
import { ReactComponent as ChevronLeft } from "assets/icons/chevron-left.svg";
import { ReactComponent as ChevronLeftCarousel } from "assets/icons/chevron-left-carousel.svg";
import { ReactComponent as ChevronRight } from "assets/icons/chevron-right.svg";
import { ReactComponent as FullScreenSvg } from "assets/icons/full-screen.svg";
import { ReactComponent as BurgerMenuSvg } from "assets/icons/burger-menu.svg";
import { ReactComponent as ChevronsDownSvg } from "assets/icons/chevrons-down.svg";
import { ReactComponent as ChevronsRightSvg } from "assets/icons/chevrons-right.svg";
import { ReactComponent as ChevronsLeftSvg } from "assets/icons/chevrons-left.svg";
import { ReactComponent as CheckboxDefaultSvg } from "assets/icons/checkbox-default.svg";
import { ReactComponent as CheckboxCheckedSvg } from "assets/icons/checkbox-checked.svg";
import { ReactComponent as CheckSvg } from "assets/icons/check.svg";
import { ReactComponent as PencilSvg } from "assets/icons/pencil.svg";
import { ReactComponent as PendingCheck } from "assets/icons/pending-check.svg";
import { ReactComponent as NewPencilSvg } from "assets/icons/newpencil.svg";
import { ReactComponent as NewCheckSvg } from "assets/icons/newcheck.svg";
import { ReactComponent as OnlineSvg } from "assets/icons/online.svg";
import { ReactComponent as HighSchoolSvg } from "assets/icons/high-school.svg";
import { ReactComponent as CollegeSvg } from "assets/icons/college.svg";
import { ReactComponent as FineArtsSvg } from "assets/icons/fine-arts.svg";
import { ReactComponent as FamilySvg } from "assets/icons/family.svg";
import { ReactComponent as MenSvg } from "assets/icons/male.svg";
import { ReactComponent as WomenSvg } from "assets/icons/female.svg";
import { ReactComponent as VeteransSvg } from "assets/icons/veterans.svg";
import { ReactComponent as SpecialNeedsSvg } from "assets/icons/special-needs.svg";
import { ReactComponent as DisabilitiesSvg } from "assets/icons/disability.svg";
import { ReactComponent as LgbtqSvg } from "assets/icons/lgbtq.svg";
import { ReactComponent as PhoneSvg } from "assets/icons/phone.svg";
import { ReactComponent as NewLogoSvg } from "assets/icons/newlogo.svg";
import { ReactComponent as LaptopSvg } from "assets/icons/laptop.svg";
import { ReactComponent as ToiletSvg } from "assets/icons/toilet.svg";
import { ReactComponent as WaterSvg } from "assets/icons/water.svg";
import { ReactComponent as LegalSvg } from "assets/icons/legal.svg";
import { ReactComponent as HealthSvg } from "assets/icons/health.svg";
import { ReactComponent as FoodSvg } from "assets/icons/food.svg";
import { ReactComponent as PendingClockSvg } from "assets/icons/pending-clock.svg";
import { ReactComponent as CirclesSvg } from "assets/icons/circles.svg";
import { ReactComponent as LinkSvg } from "assets/icons/link.svg";
import { ReactComponent as WifiSvg } from "assets/icons/wifi.svg";
import { ReactComponent as LocationPinSearchSvg } from "assets/icons/location-pin-search.svg";
import { ReactComponent as LocationMapPin } from "assets/icons/location-pin.svg";

export enum IconType {
  phone = "phone",
  plus = "plus",
  menu = "menu",
  home = "home",
  book = "book",
  user = "user",
  lock = "lock",
  swap = "swap",
  bell = "bell",
  link = "link",
  heart = "heart",
  minus = "minus",
  close = "close",
  "close-alt" = "close-alt",
  loader = "loader",
  search = "search",
  filter = "filter",
  logout = "logout",
  message = "message",
  location = "location",
  "map-pin" = "map-pin",
  navigation = "navigation",
  "logo-small" = "logo-small",
  "brief-case" = "brief-case",
  "arrow-left" = "arrow-left",
  "burger-menu" = "burger-menu",
  "full-screen" = "full-screen",
  "chevron-down" = "chevron-down",
  "chevron-left" = "chevron-left",
  "chevron-left-carousel" = "chevron-left-carousel",
  "chevron-right" = "chevron-right",
  "chevrons-down" = "chevrons-down",
  "chevrons-right" = "chevrons-right",
  "chevrons-left" = "chevrons-left",
  "checkbox-default" = "checkbox-default",
  "checkbox-checked" = "checkbox-checked",
  check = "check",
  pencil = "pencil",
  "pending-check" = "pending-check",
  newpencil = "newpencil",
  newcheck = "newcheck",
  online = "online",
  "high-school" = "high-school",
  college = "college",
  "fine-arts" = "fine-arts",
  family = "family",
  lgbtq = "lgbtq",
  men = "men",
  women = "women",
  veterans = "veterans",
  "special-needs" = "special-needs",
  disabilities = "disabilities",
  newlogo = "newlogo",
  laptop = "laptop",
  toilet = "toilet",
  water = "water",
  legal = "legal",
  health = "health",
  food = "food",
  "pending-clock" = "pending-clock",
  circles = "circles",
  "location-pin-search" = "location-pin-search",
  "location-map-pin" = "location-map-pin",
  wifi = "wifi",
  // "logo-flex-black" = "logo-flex-black",
}

const icons = {
  [IconType.phone]: <PhoneSvg />,
  [IconType.pencil]: <PencilSvg />,
  [IconType.plus]: <PlusSvg />,
  [IconType.user]: <UserSvg />,
  [IconType.book]: <BookSvg />,
  [IconType.home]: <HomeSvg />,
  [IconType.menu]: <MenuSvg />,
  [IconType.bell]: <BellSvg />,
  [IconType.swap]: <SwapSvg />,
  [IconType.lock]: <LockSvg />,
  [IconType.heart]: <HeartSvg />,
  [IconType.minus]: <MinusSvg />,
  [IconType.close]: <CloseSvg />,
  [IconType.link]: <LinkSvg />,
  [IconType["close-alt"]]: <CloseAltSvg />,
  [IconType.loader]: <LoaderSvg />,
  [IconType.logout]: <LogoutSvg />,
  [IconType.filter]: <FilterSvg />,
  [IconType.search]: <SearchSvg />,
  [IconType.message]: <MessageSvg />,
  [IconType["map-pin"]]: <MapPinSvg />,
  [IconType.location]: <LocationSvg />,
  [IconType["logo-small"]]: <SmallLogo />,
  [IconType.navigation]: <NavigationSvg />,
  [IconType["brief-case"]]: <BriefcaseSvg />,
  [IconType["arrow-left"]]: <ArrowLeftSvg />,
  [IconType["chevron-left"]]: <ChevronLeft />,
  [IconType["chevron-left-carousel"]]: <ChevronLeftCarousel />,
  [IconType["chevron-right"]]: <ChevronRight />,
  [IconType["chevron-down"]]: <ChevronDown />,
  [IconType["burger-menu"]]: <BurgerMenuSvg />,
  [IconType["full-screen"]]: <FullScreenSvg />,
  [IconType["chevrons-down"]]: <ChevronsDownSvg />,
  [IconType["chevrons-right"]]: <ChevronsRightSvg />,
  [IconType["chevrons-left"]]: <ChevronsLeftSvg />,
  [IconType["checkbox-default"]]: <CheckboxDefaultSvg />,
  [IconType["checkbox-checked"]]: <CheckboxCheckedSvg />,
  [IconType.check]: <CheckSvg />,
  [IconType["pending-check"]]: <PendingCheck />,
  [IconType.newpencil]: <NewPencilSvg />,
  [IconType.newcheck]: <NewCheckSvg />,
  [IconType.online]: <OnlineSvg />,
  [IconType["high-school"]]: <HighSchoolSvg />,
  [IconType.college]: <CollegeSvg />,
  [IconType["fine-arts"]]: <FineArtsSvg />,
  [IconType.family]: <FamilySvg />,
  [IconType.lgbtq]: <LgbtqSvg />,
  [IconType.women]: <WomenSvg />,
  [IconType.men]: <MenSvg />,
  [IconType.veterans]: <VeteransSvg />,
  [IconType["special-needs"]]: <SpecialNeedsSvg />,
  [IconType.disabilities]: <DisabilitiesSvg />,
  [IconType.newlogo]: <NewLogoSvg />,
  [IconType.laptop]: <LaptopSvg />,
  [IconType.toilet]: <ToiletSvg />,
  [IconType.water]: <WaterSvg />,
  [IconType.legal]: <LegalSvg />,
  [IconType.health]: <HealthSvg />,
  [IconType.food]: <FoodSvg />,
  [IconType.wifi]: <WifiSvg />,
  [IconType["pending-clock"]]: <PendingClockSvg />,
  [IconType.circles]: <CirclesSvg />,
  [IconType["location-pin-search"]]: <LocationPinSearchSvg />,
  [IconType["location-map-pin"]]: <LocationMapPin />,
};
interface IconProps {
  className?: string;
  icon: keyof typeof IconType;
  styles?: CSSProperties;
}

export const Icon = ({ icon, className, styles }: IconProps) =>
  cloneElement(icons[icon], {
    className: className || `${icon}-icon`,
    ...styles,
  });
